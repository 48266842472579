import React from "react";
import MainLayout from "src/layouts/Main";
import PageTitleHero from "components/PageTitleHero";
import { graphql } from "gatsby";
import PortfolioCard from "components/PortfolioCard";

export default ({ data }) => {
  const pageContent = data.allPageContentJson.edges[0];
  const __ = data.allPortfolioJson.edges;

  const cards = (stacked = false) => {
    return __.map((_, i) => {
      return (
        <PortfolioCard
          stacked={stacked}
          key={i}
          imageURL={_.node.imageURL}
          title={_.node.title}
          description={_.node.description}
          repo={_.node.repo}
          liveSiteURL={_.node.liveSiteURL}
        />
      );
    });
  };

  return (
    <MainLayout>
      <PageTitleHero title="About <span>Me</span>!" class="contact-hero" />
      <div className="flex-content-container">
        <div className="content-container col-one hidden-stack">
          <div className="feature-text">
            <p>
              About<span></span>
            </p>
          </div>
          <div className="main-content ">
            {/* <p>
              Because of my desire to learn, after recently graduating with a
              Computer Technology degree along with gaining experience in
              software development from teaching myself, I decided to jump start
              my career by applying to Nashville Software School where I enjoy
              learning from my classmates as well as help them learn by
              answering any questions they have that I know the answer to. And
              if I don't know the solution, I definitely know how to find it in
              a couple of Google searches. I am looking for a Full-Stack
              Software Engineer position to help feed my love of learning and
              help myself and others succeed and push through any barrier put in
              my way.
            </p> */}
            {/* <div className="feature-text">
              <p>
                More About Me<span></span>
              </p>
            </div> */}
            <p>
              Hi, I'm Trinity Christiana, a Nashville Native, with a desire to
              learn. After recently graduating with a Computer Technology degree
              along with gaining experience in software development from
              teaching myself, I decided to jump start my career by applying to
              Nashville Software School where I enjoy learning from my
              classmates as well as help them learn by answering any questions
              they have that I know the answer to. And if I don't know the
              solution, I definitely know how to find it in a couple of Google
              searches. I am looking for a Full-Stack Software Engineer position
              to help feed my love of learning and help myself and others
              succeed and push through any barrier put in my way.{" "}
            </p>
            <p>
              Before I decided to make programming my career, I went to
              Nashville State Community College for their Dual enrollment
              program. While there I completed all of my High School
              requirements at the age of 16.
            </p>
            <div className="quote">
              <p>
                ... completed all of my High School requirements at the age of
                16.
              </p>
            </div>
            <p>
              After graduating with my HS diploma, I decided to stay at
              Nashville State to get an Associates Degree. In deciding what I
              wanted to Major in, I combined my love of Music and my love of
              technology and went for the Associates of Applied Science with an
              emphasis in Music Technology.
            </p>
            <p>
              For this degree, I had to take a few electives, so I joined the
              Jazz Ensemble, fell in love with it and became their most popular
              Jazz Singer on Campus. I even represented Nashville State by
              performing all around Nashville at places like the Farmers Market,
              Libraries, Tennessee State University and at even campus events.
            </p>
            <p>
              For this AAS degree, I also had to take some computer science and
              coding classes which actually rekindled my love for programming. I
              had been introduced to programming 4 to 3 years prior to taking
              those classes with a program called Sratch and self teeaching. But
              taking those classes at Nashville State, and hearing about NSS at
              about the same time, helped me realize that I could actually do
              this for a living.
            </p>
            <p>
              Programming is the perfect fit for me. It’s creative, flexible,
              and works out the brain like Music does. Also like music there are
              many ways to go about solving different problems.
            </p>
            <p>
              With that I love that no matter how much experience I have with
              programming, I can always find something new to learn.
            </p>

            <div className="quote">
              <p>
                No matter how much experience I have with programming, I can
                always find something new to learn.
              </p>
              <p className="signature">- Trinity Christiana -</p>
            </div>
            <p>
              I am a lover of music and of education. I am not afraid of hard
              work. Be a part of my journey of “Becomming the Future of
              development.” If you need new talent, don’t hesitate to {""}
              <a
                className="color-link"
                href="https://trinitychristiana.netlify.app/contact/"
              >
                contact me
              </a>
              .
            </p>
          </div>
        </div>
        <div className="col-two hide-on-stack">
          <div className="sidebar-header">
            <p>
              Featured Work<span></span>
            </p>
          </div>
          <div className="level-9-margin-top flex-content-container portcards">
            {cards(true)}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export const pageQuery = graphql`
  query {
    allPageContentJson(filter: { templateKey: { eq: "home" } }) {
      edges {
        node {
          headline
        }
      }
    }

    allPortfolioJson(limit: 3, sort: { fields: itemNumber }) {
      edges {
        node {
          itemNumber
          imageURL
          title
          description
          repo
          liveSiteURL
        }
      }
    }
  }
`;

// allTutorialsJson(limit:3) {
//     edges {
//         node {
//             title
//             date
//             body
//             description
//             fields {
//                 slug
//             }
//         }
//     }
// }
